.size-small-icon-no {
  align-items: center;
  background-color: #ffffff40;
  border-radius: 8px;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  padding: 8px;
  position: relative;
}

.size-small-icon-no .button {
  color: var(--white);
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

div#app {
  margin-bottom: 50px;
}

div#app:after {
  content: " ";
  display: block;
  width: 100%;
  height: 50px;
  background: var(--primary);
}
