:root {
  --copy-font-family: "Poppins-Medium", Helvetica;
  --copy-font-size: 12px;
  --copy-font-style: normal;
  --copy-font-weight: 500;
  --copy-letter-spacing: 0px;
  --copy-line-height: normal;
  --danger: #c03825;
  --h1-font-family: "Poppins-Medium", Helvetica;
  --h1-font-size: 16px;
  --h1-font-style: normal;
  --h1-font-weight: 500;
  --h1-letter-spacing: -.3px;
  --h1-line-height: normal;
  --h2-font-family: "Poppins-Medium", Helvetica;
  --h2-font-size: 14px;
  --h2-font-style: normal;
  --h2-font-weight: 500;
  --h2-letter-spacing: 0px;
  --h2-line-height: normal;
  --icon-01: #005b45;
  --icon-02: #e79fa8;
  --neutral-100: #aba9a5;
  --neutral-50: #f4f0ec;
  --neutral-700: #4a4948;
  --primary: #0a3a67;
  --secondary: #001825;
  --shadow: 0px 0px 4px 0px #0003;
  --tertiary: #dee3d8;
  --variable-collection-background: #001825;
  --variable-collection-tertiary: #dbd6cc;
  --white: #fff;
}

.size-small-icon-no {
  background-color: #ffffff40;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.size-small-icon-no .button {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

div#app {
  margin-bottom: 50px;
}

div#app:after {
  content: " ";
  background: var(--primary);
  width: 100%;
  height: 50px;
  display: block;
}

.element {
  background-color: #f4f0ec;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.element .element-px {
  background-color: var(--neutral-50);
  position: relative;
}

.element .frame {
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: absolute;
}

.element .img {
  flex: none;
  position: relative;
}

.element .size-small-icon-no-text-yes-type-secondary-hover-no-disabled-no {
  background-color: #0018251a !important;
  flex: none !important;
}

.element .size-small-icon-no-instance {
  color: var(--secondary) !important;
  font-size: 16px !important;
}

.element .div {
  flex-direction: column;
  gap: 16px;
  display: flex;
  position: absolute;
}

.element .untitled-artwork {
  position: relative;
}

.element .explore-like-an-expert {
  color: var(--secondary);
  letter-spacing: -.3px;
  font-family: Poppins, Helvetica;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .text-wrapper {
  color: #000;
  letter-spacing: -.3px;
  align-self: stretch;
  font-family: Poppins, Helvetica;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.element .frame-2 {
  display: flex;
  position: absolute;
}

.element .frame-3 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: flex;
  position: relative;
}

.element .img-wrapper {
  background-color: #fff;
  border-radius: 8px;
  width: 52px;
  height: 52px;
  position: relative;
  overflow: hidden;
}

.element .img-2 {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 14px;
  left: 14px;
}

.element .frame-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.element .text-wrapper-2 {
  color: #000;
  letter-spacing: -.3px;
  text-align: center;
  width: 162px;
  height: 33px;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .p {
  color: #000;
  letter-spacing: -.3px;
  text-align: center;
  align-self: stretch;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.element .frame-5, .element .frame-6 {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: flex;
  position: relative;
}

.element .frame-7 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 443px;
  display: inline-flex;
  position: absolute;
  top: 253px;
  left: 128px;
}

.element .vector {
  width: 64px;
  height: 64px;
  position: relative;
}

.element .text-wrapper-3 {
  color: var(--secondary);
  letter-spacing: -.3px;
  width: 443px;
  font-family: Poppins, Helvetica;
  font-size: 77px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.element .text-wrapper-4 {
  color: #000;
  letter-spacing: -.3px;
  align-self: stretch;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.element .frame-8 {
  justify-content: center;
  align-items: flex-start;
  gap: 100px;
  width: 944px;
  display: flex;
  position: absolute;
  top: 1033px;
  left: 128px;
}

.element .frame-9 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 24px;
  display: flex;
  position: relative;
}

.element .group {
  position: absolute;
}

.element .frame-10 {
  justify-content: center;
  align-items: flex-start;
  gap: 100px;
  width: 1136px;
  display: flex;
  position: absolute;
  top: 1086px;
  left: 188px;
}

div#app {
  margin-bottom: 50px;
}

div#app:after {
  content: " ";
  background: var(--primary);
  width: 100%;
  height: 50px;
  display: block;
}

/*# sourceMappingURL=index.f5979449.css.map */
