:root {
  --copy-font-family: "Poppins-Medium", Helvetica;
  --copy-font-size: 12px;
  --copy-font-style: normal;
  --copy-font-weight: 500;
  --copy-letter-spacing: 0px;
  --copy-line-height: normal;
  --danger: rgba(192, 56, 37, 1);
  --h1-font-family: "Poppins-Medium", Helvetica;
  --h1-font-size: 16px;
  --h1-font-style: normal;
  --h1-font-weight: 500;
  --h1-letter-spacing: -0.30000001192092896px;
  --h1-line-height: normal;
  --h2-font-family: "Poppins-Medium", Helvetica;
  --h2-font-size: 14px;
  --h2-font-style: normal;
  --h2-font-weight: 500;
  --h2-letter-spacing: 0px;
  --h2-line-height: normal;
  --icon-01: rgba(0, 91, 69, 1);
  --icon-02: rgba(231, 159, 168, 1);
  --neutral-100: rgba(171, 169, 165, 1);
  --neutral-50: rgba(244, 240, 236, 1);
  --neutral-700: rgba(74, 73, 72, 1);
  --primary: rgba(10, 58, 103, 1);
  --secondary: rgba(0, 24, 37, 1);
  --shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  --tertiary: rgba(222, 227, 216, 1);
  --variable-collection-background: rgba(0, 24, 37, 1);
  --variable-collection-tertiary: rgba(219, 214, 204, 1);
  --white: rgba(255, 255, 255, 1);
}
