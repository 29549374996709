.element {
  background-color: #f4f0ec;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.element .element-px {
  background-color: var(--neutral-50);
  position: relative;
}

.element .frame {
  align-items: center;
  display: inline-flex;
  gap: 24px;
  position: absolute;
}

.element .img {
  flex: 0 0 auto;
  position: relative;
}

.element .size-small-icon-no-text-yes-type-secondary-hover-no-disabled-no {
  background-color: #0018251a !important;
  flex: 0 0 auto !important;
}

.element .size-small-icon-no-instance {
  color: var(--secondary) !important;
  font-size: 16px !important;
}

.element .div {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
}

.element .untitled-artwork {
  position: relative;
}

.element .explore-like-an-expert {
  color: var(--secondary);
  font-family: "Poppins", Helvetica;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: normal;
  position: relative;
}

.element .text-wrapper {
  align-self: stretch;
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: normal;
  position: relative;
}

.element .frame-2 {
  display: flex;
  position: absolute;
}

.element .frame-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.element .img-wrapper {
  background-color: #ffffff;
  border-radius: 8px;
  height: 52px;
  overflow: hidden;
  position: relative;
  width: 52px;
}

.element .img-2 {
  height: 24px;
  left: 14px;
  position: absolute;
  top: 14px;
  width: 24px;
}

.element .frame-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.element .text-wrapper-2 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 33px;
  letter-spacing: -0.3px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 162px;
}

.element .p {
  align-self: stretch;
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.element .frame-5 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.element .frame-6 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.element .frame-7 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  left: 128px;
  position: absolute;
  top: 253px;
  width: 443px;
}

.element .vector {
  height: 64px;
  position: relative;
  width: 64px;
}

.element .text-wrapper-3 {
  color: var(--secondary);
  font-family: "Poppins", Helvetica;
  font-size: 77px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: normal;
  position: relative;
  width: 443px;
}

.element .text-wrapper-4 {
  align-self: stretch;
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: normal;
  position: relative;
}

.element .frame-8 {
  align-items: flex-start;
  display: flex;
  gap: 100px;
  justify-content: center;
  left: 128px;
  position: absolute;
  top: 1033px;
  width: 944px;
}

.element .frame-9 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  position: relative;
}

.element .group {
  position: absolute;
}

.element .frame-10 {
  align-items: flex-start;
  display: flex;
  gap: 100px;
  justify-content: center;
  left: 188px;
  position: absolute;
  top: 1086px;
  width: 1136px;
}

div#app {
  margin-bottom: 50px;
}

div#app:after {
  content: " ";
  display: block;
  width: 100%;
  height: 50px;
  background: var(--primary);
}
